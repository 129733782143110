<template>
  <div class="home">
    <div class="top">
      <div class="top_left">
      <img class="topImage" src="@/assets/img/top_image.png" style="">
      <span class="top_text">商汤科技Sensechat_edu私有化部署定制版v1.0</span>
    </div>
    <div class="top_right" @click="backHome">
      <img class="help_image" src="@/assets/img/backHomeImage.png" style="">
      <!-- <span class="help_text">返回首页</span> -->
    </div>
    </div>
    
    <div class="el-container" height="100%">
      <div class="main">
          <div class="mian_body">
            <div class="mian_left">
              <ul class="menu-list">
                <li
                  v-for="(item, index) in menuList"
                  :key="index"
                  class="nav_class"
                  :class="{ activeNav: index == current }"
                  @click="changeMenu(index)"
                  >
                  <div class="block"></div>
                  <img class="top_image" :src="item.image" style="">
                  <span class="iconfont"  style="font-size:13px;margin-left:10px">{{item.name}}</span>
                </li>
              </ul>
            </div>
            <div class="mian_right">
                <div class="base_intro" v-if="current==0">
                      <h1 >应⽤简介</h1>
                      <p style="margin-top: 10px;">本应⽤是⼀个基于商汤科技⽂⽣图⼤模型的AIGC创作平台。本应⽤具有下列主要功能：</p>   
                      <div style="margin-left:22px;margin-top: 5px;">
                        <p>- ⽂⽣图：⽀持⽤户使⽤⼤模型进⾏提示词进⾏“⽂⽣图”。</p>
                        <p>- 图⽣图：⽀持⽤户上传参考图，选定所需要的控制⽹络（ControlNet），进⾏ &ldquo;图⽣图&rdquo;。</p>
                        <p>- LoRa训练：⽀持⽤户上传图⽚进⾏“LoRa训练”（Low-Rank Adaptation of Large Language Models）、得到⾃定义的微调模型，体验AI模型训练的基本过程、打造个⼈专属的⽣成式Al模型，创作专属于⾃⼰的⻛格的画作。</p>
                      </div>  
                      <div style="margin-top: 5px;">
                        通过⽂⽣图、图⽣图和便捷的LoRa训练能⼒，AIGC创作平台提供提供便利、完善的内容⽣产创作⼯具。⽤户可迅速⽣成超强质感、超⾼精的画作。
                      </div>                 
                </div>     
                <div class="base_intro" v-if="current==1">
                      <h1>提示词⼯程</h1>
                      <p style="margin-top: 10px;">在AIGC创作平台中，提示词是影响图像⽣成效果的关键因素。撰写好的提示词有助于引导⼤模型准确理解⽤户需求，从⽽输出更符合预期的⾼质量图像。AIGC⽣成画作的效果不理想，通常是由于提示词不合适⽽导致的。本节将介绍⼏种有效的提示词撰写策略和技巧。</p>   
                      <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">1. 明确的描述性词汇</h1>
                          <div style="margin-left:22px">
                            <p>使⽤清晰、具体的词汇来描述你希望⽣成的图像细节。避免模糊的词语，多使⽤形容词和名词，以提⾼⽣成结果的准确性。</p>
                            <p>
                             <img class="t_image" src="@/assets/images/2024111522434523.png" style="">
                              <img class="t_image" src="@/assets/images/2024113522443734.png" style="">
                            </p>
                            <p> • 示例：如果对于画作中的内容有具体的要求，⽐起“美丽的花”，可以更明确地写成“⼀朵盛开的粉红⾊玫瑰花，背景是柔和的⾦⾊⽇落”，更能满⾜⼼中所想。</p>
                          </div>  
                      </div>  

                       <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">2. 加⼊⻛格词汇</h1>
                          <div style="margin-left:22px">
                            <p>在提示词中加⼊⻛格、氛围等词汇，能够有效引导⽣成特定艺术⻛格的作品。⻛格词汇可以包括“简约”、“复古”、“未来感”等。</p>
                            <p>
                             <img class="t_image" src="@/assets/images/2024112323225249.png" style="">
                            </p>
                            <p> • 示例：“复古⻛格的咖啡馆内部，暖⻩⾊灯光，⽊质家具和绿植环绕”。</p>
                          </div>  
                      </div>

                       <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">3. 利⽤颜⾊、材质等细节</h1>
                          <div style="margin-left:22px">
                            <p>颜⾊和材质可以为图像添加层次感，使得⽣成结果更有质感。结合具体的颜⾊、质感、场景描述，让模型更好地理解你的预期。在提示词中加⼊“4k”、“⾼清”等词语，可以使模型输出质感更好的图像。</p>
                            <p>
                             <img class="t_image" src="@/assets/images/20241115343225625.png" style="">
                            </p>
                            <p> 示例：“⼥⼠深蓝⾊的丝绸礼服，带有细腻的光泽，背景为古典的欧洲宫殿，⾼清，4k”。</p>
                          </div>  
                      </div>

                        <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">4. 结构性描述和构图</h1>
                          <div style="margin-left:22px">
                            <p>提示词可以包含构图信息，以便模型更清晰地理解不同元素之间的位置关系。包括“前景”、“背景”、“中⼼位置”等构图提示。</p>
                            <p>
                             <img class="t_image" src="@/assets/images/1202411152323230049.png" style="">
                            </p>
                            <p> 示例：“前景是⼀名身穿⿊⾊⽪夹克的年轻⼈，背景为繁忙的都市夜景”。</p>
                          </div>  
                      </div>

                      <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">5. 避免歧义，注意语序</h1>
                          <div style="margin-left:22px">
                            <p>为了避免歧义，确保提示词的语序逻辑清晰，先描述主要元素，再补充细节。避免过多修饰词汇挤在⼀起，导致⽣成效果偏差。</p>
                            <p>
                             <img class="t_image" src="@/assets/images/320241115-230924.png" style="">
                             <img class="t_image" src="@/assets/images/3202411153231001.png" style="">
                            </p>
                            <p> • 示例：写“穿⽩⾊⻓裙的⼥孩站在湖边，夕阳在远处”⽐“夕阳湖边的⽩裙⼥孩”更清晰。</p>
                          </div>  
                      </div>               
                </div>  

                 <div class="base_intro" v-if="current==2">
                    <h1 >基础和⾼级设置</h1>
                    <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">1. 基础参数设置</h1>
                          <div style="margin-left:22px">
                            <p>在AIGC创作平台中，为了帮助您⽣成更加符合需求的图像，平台提供了多种基础设置参数，便于您调整图像⽐例、画质以及⽣成数量，甚⾄⽀持⾃定义分辨率。</p>
                            <h1  style="font-size:13px">⽐例设置</h1>
                            <p>您可以选择图像的宽⾼⽐例，以适应不同的创作场景需求：</p>
                            <div style="margin-left:22px">
                                <p> • 1:1：正⽅形，适⽤于头像或⽅形构图的画⾯。</p>
                                <p> • 4:3：经典⽐例，适合展示较多细节的画⾯。</p>
                                <p> • 16:9：宽屏⽐例，适合⻛景、电影海报等宽幅画⾯。</p>
                            </div>

                            <h1  style="font-size:13px">画质选择</h1>
                            <p>平台提供不同的图像分辨率选项，以平衡图像质量和⽣成速度：</p>
                            <div style="margin-left:22px">
                                <p> • 540p：中等画质，适合快速预览或⼩尺⼨应⽤。</p>
                                <p> • 1080p：较⾼画质，适合需要更多细节的⾼质量作品。</p>
                                <p> • ⾃定义分辨率：如果您对图像的分辨率有特殊需求，可以直接输⼊图像的⻓宽像素值，以⾃定义分辨率。这⼀功能⾮常适合对输出图像⼤⼩有精确要求的创作者。</p>
                            </div>

                            <h1  style="font-size:13px">数量选择</h1>
                            <p>您可以选择每次⽣成图像的数量：</p>
                            <div style="margin-left:22px">
                                <p> • 1-2张：允许每次⽣成1到2张图像，以便对⽐和选择不同的⽣成结果。</p>
                            </div>
                            <p>这些基础设置参数为您的创作提供了灵活的调整空间，您可以根据项⽬的不同需求⾃由选择和组合。建议在尝试新项⽬时，先进⾏⼩尺⼨、低画质的预览，确保效果后再调整⾄更⾼画质，达到最佳的⽣成体验。</p>                                
                          </div>  
                    </div> 

                     <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">2. ⾼级参数设置</h1>
                          <div style="margin-left:22px">
                            <p>在AIGC创作平台中，⾼级参数设置为有更⾼创作需求的⽤户提供了精细化控制。这些参数包括种⼦数、步数和约束强度，可以帮助您更精准地影响⽣成结果的细节和⼀致性。对于不熟悉这些参数或没有明确需求的⽤户，建议采⽤系统预设的默认值。</p>
                            <h1  style="font-size:13px">种⼦数（Seed）</h1>
                            <p>种⼦数控制⽣成图像的随机性和⼀致性。相同的提示词在相同的种⼦数下会⽣成相似的图像，⽽不同的种⼦数则会带来不同的变化效果。</p>
                            <div style="margin-left:22px">
                               <p> •  推荐值：默认留空。</p>
                                <p> •  作⽤：让⽤户在多次⽣成中保持相似⻛格，或在同⼀提示词下产⽣不同效果。若想在不同尝试中获得⼀致的结果，可固定种⼦数；若希望⽣成更多样化的效果，可每次选择不同的种⼦数。</p>
                            </div>

                            <h1  style="font-size:13px">步数（Steps）</h1>
                            <p>步数决定了AI模型在⽣成图像过程中进⾏计算的步数。较⾼的步数通常会带来更精细的图像细节，但也会增加⽣成时间。</p>
                            <div style="margin-left:22px">
                                <p> •  推荐值：默认20。</p>
                                <p> • 作⽤：影响图像的清晰度和细节处理，步数越⾼，⽣成的图像细节越丰富。若希望⽣成更细腻的作品，可适当增加步数，更⾼的步数意味着更好的效果、更久的⽣成时间；若需快速⽣成，使⽤较低步数。</p>
                            </div>

                            <h1  style="font-size:13px">约束强度控制（Guidance Strength）</h1>
                            <p>约束强度控制⽣成图像的“引导程度”，即提示词对⽣成结果的影响⼒度。较⾼的约束强度会让⽣成图像更贴近提示词描述，但过⾼的设置可能导致图像失去⾃然性。</p>
                            <div style="margin-left:22px">
                              <p> •  推荐值：默认3。</p>
                                <p> • 作⽤：帮助⽤户控制⽣成图像的⻛格和贴合度，使图像更符合提示词的描述。如果希望⽣成结果与提示词更严格匹配，可适当提⾼约束强度；若需要更⾃然、⾃由的⻛格，可降低约束强度。</p>
                            </div>                              
                          </div>  
                    </div>                 
                </div>  

                 <div class="base_intro" v-if="current==3">
                    <h1 >⽂⽣图</h1>
                    <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">1. 在AIGC平台中，输⼊提示词即可完成图像的⽣成</h1>
                          <div style="margin-left:22px">    

                            <div style="margin-left:22px">
                              <h1  style="font-size:13px">• 输⼊提示词：</h1>
                              <p>  在提示词输⼊框中输⼊描述性⽂本。</p>
                            </div>

                            <div style="margin-left:22px">
                              <h1  style="font-size:13px">• 点击“⽴即⽣成”：</h1>
                              <p>  输⼊提示词后，点击“⽴即⽣成”按钮，平台将根据提示词⾃动⽣成图像。⼏秒钟内，您将看到平台根据⽂字描述呈现出的AIGC画作。</p>
                            </div>   
                          </div>  
                    </div> 
                     <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">2. 提示词优化与反向提示词</h1>
                          <div style="margin-left:22px">
                            <p>为了提升图像⽣成效果，平台提供了优化提示词和反向提示词两种辅助功能，帮助您精确控制⽣成结果：</p>
                            
                          
                            <div style="margin-left:22px">
                              <h1  style="font-size:13px">• 优化提示词：</h1>
                              <p>  当该功能开启时，平台会调⽤AI⼤语⾔模型，在理解所输⼊内容的基础上⾃动优化提示词，以⽣成更符合预期的⾼质量图像。这对于需要细节增强或⻛格调整的图像⾮常有帮助。</p>
                            </div>

                            <div style="margin-left:22px">
                              <h1  style="font-size:13px">• 反向提示词：</h1>
                              <p>  启⽤此功能时，您可以指定要排除的元素，帮助平台避免⽣成不需要的特定内容。例如，在描述⼀⽚树林时，可以在反向提示词中填⼊“雾⽓”、“阴天”，以更精确地获得想要呈现的视觉效果。</p>
                            </div>

                            
                            <p>可以通过界⾯中的开关轻松启⽤或关闭优化提示词和反向提示词功能，满⾜不同创作需求。这项功能灵活实⽤，让您能够轻松探索和优化⾃⼰的创意，使⽣成的图像更贴合您的想法。在提示词的创作中，不妨尝试不同的描述和设置，体验AIGC创作的多样性和可能性！</p>
                                                         
                          </div>  
                    </div>      

                    <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">3. 模型选择 — 通⽤模型</h1>
                          <div style="margin-left:22px">
                            <p>通⽤模型具备⼴泛的⻛格⽀持和应⽤场景，采⽤国际化的数据进⾏训练，通过输⼊相应的提示词，可以⽣成多种⻛格的图⽚，包括：摄影、卡通、电影、油画等。</p>                            
                            <p>
                              <img class="gj—image" src="@/assets/images/202411252229372.png" style="">
                            </p>
                            <p>由于通⽤模型采⽤了国际化训练数据，可能会出现场景、⼈物具有⻄⽅特点的情况。如果想要更加贴合特定⽂化环境的数据，可以在提示词中进⾏明确，例如加⼊：中国、中国⻛、中国古代、亚洲，等词语。</p>
                                                  
                          </div>  
                    </div>  

                     <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top: 5px;">4. 模型选择 — ⼤师模型</h1>
                          <div style="margin-left:22px">
                            <p>不同于通⽤模型，⼤师模型是本项⽬针对中国艺术⼤师和艺术⻛格进⾏专项优化的模型。⼤师模型⽀持以下国内外艺术⻛格，选择相应的⼤师模型、输⼊提示词后，即可得到相应⻛格的图⽚。</p>                            
                            <p>
                              <img class="gj—image" src="@/assets/images/343420241125-225452.png" style="">
                            </p>
                            <p>赵⽆极⻛格（提示词：⼤海、草）：</p>
                             <p>
                              <img class="gjj—image" src="@/assets/images/23220241125-225417.png" style="">
                            </p>

                            <p>陈洪绶⻛格（提示词：⻦、花朵） ：</p>    
                            <p>
                              <img class="gjj—image" src="@/assets/images/23220241125-225242.png" style="">
                            </p>
                            <p>林⻛眠⻛格（提示词：⻦、仕⼥图）：</p>      
                            <p>
                              <img class="gjj—image" src="@/assets/images/34320241125-225031.png" style="">
                            </p>
                            <p>宋韵⻛格（提示词：男性、⽼年男性）：</p>    
                            <p>
                              <img class="gj—image" src="@/assets/images/3420241125-224842.png" style="">
                            </p>           
                          </div>  
                    </div>  


                </div> 

                <div class="base_intro" v-if="current==4">
                    <h1 >图⽣图</h1>
                    <div style="font-size:13px;margin-top: 5px;">
                        <p>图⽣图功能让您能够使⽤参考图像，通过控制⽹络（ControlNet）进⾏图像的再创作或⻛格迁移。通过上传⼀张参考图，并应⽤不同的控制⽹络，您可以赋予图像新的⻛格、结构或效果，从⽽实现更具创意的表达。这⼀功能为细节调整和⻛格统⼀提供了强⼤的⽀持，适合需要个性化定制的图像创作。</p>
                        <h1  style="font-size:13px;margin-top:20px;margin-bottom:5px"> 1.上传参考图</h1> 
                        <p>
                           <img class="tt_image" src="@/assets/images/22220241115-235807.png" style="">
                        </p>
                        <div style="margin-left:22px">
                          <p> • 选择参考图：点击“上传图⽚”按钮，选择⼀张您希望⽤作基础的参考图。</p>
                          <p> • 图⽚要求：为确保⽣成效果，建议使⽤分辨率较⾼、清晰的图⽚作为参考图，图⽚格式⽀持：.jpg / .jpeg / .png / .bmp</p>
                        </div> 

                        <h1  style="font-size:13px;margin-top:20px;margin-bottom:5px"> 2. 选择控制⽹络（ControlNet）</h1> 
                        <p>上传参考图后，您可以选择适⽤的控制⽹络来调整⽣成效果。ControlNet提供了多种控制选项，每种选项都能以不同⽅式影响图像的⽣成结果：</p>
                        <p>
                           <img class="ttt_image" src="@/assets/images/3434620241116-001404.png" style="">
                        </p>
                        <h1  style="font-size:13px;margin-top:10px;margin-bottom:5px"> 线条学习</h1> 
                         <p>
                           <img class="ttt_image" src="@/assets/images/3434620241116-001037.png" style="">
                        </p>
                        <div style="margin-left:22px">
                          <p> • 功能：通过分析图像的线条结构，保留图像的轮廓和基本结构，从⽽⽣成与原图相似但⻛格化的图像。</p>
                          <p> • 适⽤场景：适合希望保持参考图主要形态的⽤户，如插画、漫画⻛格的图像⽣成。</p>
                        </div> 

                         <h1  style="font-size:13px;margin-top:10px;margin-bottom:5px"> 亮度控制</h1> 
                        
                        <div style="margin-left:22px">
                          <p> • 功能：根据参考图的亮度信息来调整⽣成图像的明暗分布，使⽣成图在光影上更贴近参考图。</p>
                          <p> • 适⽤场景：适合需要保持或调整特定光影效果的创作，如⾼对⽐度或低光的场景图。</p>
                        </div>

                         <h1  style="font-size:13px;margin-top:10px;margin-bottom:5px"> 姿势迁移</h1> 
                         <p>
                           <img class="tzs_image" src="@/assets/images/3456202411161002858.png" style="  width: 450px;    height: 260px;">
                        </p>
                        <div style="margin-left:22px">
                          <p> • 功能：识别并复现参考图中⼈物或物体的姿势，⽣成的新图像会尽可能匹配原图中的姿势状态。</p>
                          <p> • 适⽤场景：适⽤于⼈物插画或需要特定姿势、动作的场景，让⽣成结果更加逼真和灵活。</p>
                        </div> 


                         <h1  style="font-size:13px;margin-top:10px;margin-bottom:5px"> 深度迁移</h1> 
                         <p>
                           <img class="tzs_image" src="@/assets/images/343202411163302738.png" style="  width: 450px;    height: 180px;">
                        </p>
                        <div style="margin-left:22px">
                          <p> • 功能：捕捉参考图的深度信息，保持物体的前后关系和空间感，使⽣成图在结构上更具⽴体感。</p>
                          <p> • 适⽤场景：适合⻛景图、建筑图等场景，通过空间感增强⽣成图的真实度。</p>
                        </div> 


                         <h1  style="font-size:13px;margin-top:10px;margin-bottom:5px"> 线稿学习</h1> 
                         <p>
                           <img class="tzs_image" src="@/assets/images/34620241116702618.png" style="  width: 450px;    height: 180px;">
                        </p>
                        <div style="margin-left:22px">
                          <p> • 功能：识别并应⽤参考图中的线稿，⽣成图像将参考线稿的布局和形状，以实现精细的线条表达。</p>
                          <p> • 适⽤场景：适合草图完善和插画创作，帮助⽤户快速将草图转化为成品图。</p>
                        </div> 

                        <h1  style="font-size:13px;margin-top:20px;margin-bottom:5px"> 3. 图⽣图的应⽤场景</h1> 
                        <p>图⽣图功能可以应⽤在各种创作场景中，例如⻆⾊设计、⻛景图的⻛格化处理，或将草图转化为⾼质量的插画。通过ControlNet的多种控制选项，⽤户可以⾃由组合这些参数，尝试不同的⻛格和效果。</p>

                    </div>                
                </div>
                
                <div class="base_intro" v-if="current==5">
                  <h1 >LoRA训练</h1>
                  <div style="font-size:13px;margin-top: 5px;">
                        <p>LoRA训练功能让⽤户可以通过上传特定的图像集，定制出独具个性化⻛格的⽣成模型。LoRA（Low-Rank Adaptation of Large Language Models）训练技术为您提供了在现有⼤模型的基础上微调⽣成模型的能⼒，使得模型能够精准识别并再现您所定义的独特⻛格，带来⾼度个性化的图像创作体验。</p>
                        <h1  style="font-size:13px;margin-top:20px;margin-bottom:5px"> 上传训练图像集 </h1> 
                        <p>
                           <img class="tzs_image" src="@/assets/images/066a94bf-65ee-4d4f-a06f-e7b6a72f8c2f.jpg" style="  width: 464px;    height: 240px;">
                        </p>
                        <h1  style="font-size:13px;margin-top:20px;margin-bottom:5px">1. 上传图⽚：点击“上传图⽚”按钮，选择20张符合预期⻛格的图⽚，这些图⽚将⽤于训练您的LoRA模型。 </h1> 
                        <h1  style="font-size:13px;margin-top:20px;margin-bottom:5px">2. 图⽚要求：为保证训练质量，建议⽤来训练的LoRA图⽚满⾜以下要求：</h1> 
                        <div style="margin-left:22px">
                          <h1 style="font-size:13px">⻛格⼀致</h1>
                          <p> • 图⽚应具有相似的⻛格（如同⼀⾊调、绘画⼿法或艺术表现形式），避免在⻛格上差异过⼤，以确保模型能够清晰识别并提取⼀致的视觉特征。</p>
                          <p> • 示例：如果⽬标是“⽔彩⻛格”，应确保所有图⽚都为⽔彩画，避免混⼊油画或素描。</p>
                        </div> 

                        <div style="margin-left:22px">
                          <h1 style="font-size:13px">内容统⼀</h1>
                          <p> • 图⽚内容尽量聚焦于特定主题或元素（如场景、物体、⼈物等）。⼀致的内容有助于模型理解该主题的关键特征。但不要上传同样的图⽚。</p>
                          <p> • 示例：如创建花卉主题，所有图⽚最好都包含花卉元素，避免混⼊与主题⽆关的元素（如建筑或动物）。</p>
                        </div>


                        <div style="margin-left:22px">
                          <h1 style="font-size:13px">主题明确</h1>
                          <p> • 确保所选图⽚在颜⾊、形状、结构等⽅⾯具有清晰的特征，使模型能够更好地识别并学习到该⻛格的独特之处。尽量选择背景相对简单或⽆⼲扰的图⽚，以确保模型将注意⼒集中在主要元素上，提升⻛格学习的效果。</p>
                          <p> • 示例：如果主体是⼈物，建议以纯⾊或模糊背景为佳，避免杂乱背景分散模型的“注意⼒”。</p>
                          <p>
                           <img class="tzs_image" src="@/assets/images/ea5810bb-6967-4b3d-ab3c-e00e40805cdb.jpg" style="  width: 540px;    height: 280px; margin-top: 10px;">
                          </p>
                          <p> • 示例：这⾥⼀位同学想要训练⼀个LoRA模型，能⽣成⾃⼰的形象。我们标注出了不适⽤来上传进⾏LoRA训练的照⽚。</p>
                        </div>
                  </div>
                  <p>在使⽤LoRA功能上传图⽚进⾏训练时，请确保您拥有所⽤图像的版权或合法使⽤权。如果您打算使⽤他⼈发布的AIGC作品或其他创作素材，请事先获得版权持有者的许可，避免侵
犯他⼈版权。尊重版权不仅保护创作者的权益，也为您带来更健康的创作体验。请谨慎选择图⽚，遵守版权法规，让每⼀份创作都安⼼⽆忧！</p>
<p>
                           <img class="tzs_image" src="@/assets/images/3e41b22b-3de9-4505-a51b-a2f8dc749ef5.jpg" style="  width: 540px;    height: 240px; margin-top: 10px;">
                          </p>
                    <div style="font-size:13px">
                          <h1  style="font-size:13px;margin-top:20px;margin-bottom:5px"> 定义触发词
                          </h1> 
                        <p>触发词是⼀组简单的⽂字，能够帮助您在使⽤LoRA模型⽣成图像时轻松调⽤所训练的⻛格。</p>
                        <div style="margin-left:22px">
                          <p> • 触发词的定义：在训练过程中，您可以根据所训练的⻛格，为模型设定⼀个触发词，例如“吴冠中”或“⽔彩森林画”。⽇后在⽣成提示词中加⼊该触发词，即可激活训练出的⻛格效果。</p>
                          <p> • 触发词作⽤：帮助您随时调⽤⾃定义⻛格，让不同项⽬中的创作更具⼀致性。</p>
                        </div>                         
                    </div>
                    <div style="font-size:13px">
                     
                          <h1 style="font-size:13px">训练和⽣成</h1>
                          <div style="margin-left:22px">
                          <p> • 开始训练：在上传图⽚并设定触发词后，点击“开始训练”。系统会⾃动对图⽚进⾏处理，通过学习图像中的⾊彩、结构、⻛格等特征，⽣成专属于您的LoRA模型。</p>
                          <p> • ⽣成图像：训练完成后，您可以在⽂⽣图或图⽣图功能中调⽤触发词使⽤该LoRA模型，⽣成带有⾃定义⻛格的图像。此功能能够满⾜个性化需求，让您体验专属⻛格的快速创作。</p>
                    </div>
                  </div>
                        <p>LoRA功能让您能够通过简单的图⽚集上传和触发词定义，⾃主训练⽣成⼀个具备独特⻛格的专属AI模型。这不仅赋予您更强的创作掌控⼒，还让您有机会体验到AI模型训练的基本过程。通过LoRA训练，您可以亲身参与到AI模型定制流程中，⻅证⾃⼰的创意如何在AI的帮助下成为现实！</p>                      
                   


                </div>

                <div class="base_intro" v-if="current==6">
                  <h1 >⼤胆探索、学习和创造</h1>
                  <p style="margin-top: 10px;">尽管提示词有许多规则、LoRA训练有很多注意事项，但其实它们只是帮助你更好地捕捉灵感的⼯具。提示词的撰写并没有绝对的“对”与“错”，更多的是⼀种⾃由创作的表达⽅式。要让画作变得精彩，也不⼀定要完全遵循固定的套路！</p>
                  <p style="margin-top: 10px;">在AIGC创作中，最重要的是⼤胆表达你的想法，平台能够让你的灵感瞬间成型，快速验证你的创意。⽆论是细腻的⻛景还是富有个性的⻆⾊设计，简单的⼀句话就能创造出独⼀⽆⼆的作品。AIGC创作的⽆限可能就在这⾥等着你去发掘！以下是⼀些基础提示词模板，帮助你快速上⼿：</p>
                  <div style="font-size:13px">             
                      <div style="margin-left:22px;margin-top: 5px;">
                          <p> • ⻛景：试试描述⾃然的细腻美感！例如，“宁静的雪⼭⽇出，松树被⽩雪覆盖，淡蓝⾊的天空”。</p>
                          <p> • ⼈物：给⻆⾊赋予独特的个性！例如，“复古⻛格的男⼦，戴着⿊⾊宽沿帽，背景为古⽼的欧洲街道”。</p>
                          <p> • 插画⻛：让城市多⼀点梦幻⾊彩！例如，“卡通⻛格的城市街景，天空中漂浮着热⽓球”。</p>
                      </div>
                  </div>
                  <p style="margin-top: 10px;">此外，去欣赏和分析⼀些优秀的作品，看看它们使⽤了怎样的提示词表达，也是创造优秀作品的不错路径。⼀些AIGC创作社区，例如Artbreeder、PromptHero等，这些平台汇集了许多优秀作品和提示词示例，为你提供灵感参考。这些AIGC创作社区⾥有⼤量活跃的创作者，你可以参考他们的提示词，甚⾄在评论区⼀起交流灵感！（如果是别⼈已经发布的AIGC画作，则要注意版权问题哦，不过可以尝试使⽤类似的提示词，创作属于⾃⼰的作品。）</p>
                  <h1 style="margin-top: 10px;" >去冒险吧！</h1>
                  <p  style="margin-top: 10px;">每次试验都是⼀次创作的旅程，⽽你的每⼀个想法都可以让AI⽣成与众不同的图像。今天就⼤胆输⼊你的第⼀个提示词，看看会得到什么惊喜吧！</p>               
                </div>
            </div>
          </div> 
      </div>
    </div>
    
  </div>
</template>  
<script>  


export default {
  components: {
  },
  data() {
    return {
      menuList: [
        {'name':"应用简介",'image':require("@/assets/img/Vector.png")},
        {'name':"提示词工程",'image':require("@/assets/img/project.png")},
        {'name':"基础和高级设置",'image':require("@/assets/img/setting.png")},
        {'name':"文生图",'image':require("@/assets/img/Wensheng.png")},
        {'name':"图生图",'image':require("@/assets/img/Tu.png")},
        {'name':"LoRA训练",'image':require("@/assets/img/adobe-illustrate.png")},
        {'name':"探索、学习、创造",'image':require("@/assets/img/path.png")},
      ],
      current: 0,
      imgUrl: require('@/assets/img/head_portrait.jpg'),
      content:'',
    };
  },
  methods: {
    backHome(){
      this.$router.push('/ChatHome');
    },
    changeMenu(index) {  
      this.current = index;
      
      
    },
  },
};
</script>


<style lang="scss" scoped>
.base_intro{
  display:flex;flex-direction: column;font-size:14px;margin-top:30px;  
  line-height: 30px;color: rgba(56, 56, 56, 1);text-align: left;margin-left:60px;margin-right:20px;
  //   height: 760px; /* 设置一个固定高度 */
  // overflow-y: auto; /* 使得内容超过高度时可以垂直滚动 */
  
}
.t_image{
  width: 160px;
  height: 160px;
  margin-right: 10px;
}
.tt_image{
  width: 460px;
  height: 160px;
  margin-right: 10px;
}
.ttt_image{
  width: 590px;
  height: 140px;
  margin-right: 10px;
}
.tzs_image{
  width: 360px;
  height: 160px;
  margin-right: 10px;
}
.topImage{
 width: 60px;
height: 32px;



}
.activeNav{
  width: 180px;
  height: 15px;
  opacity: 1;
  border-radius: 200px;
  background: rgba(247, 247, 247, 1);
}

.nav_class{
display: flex;
flex-direction: row;
align-content: center;
text-align: center;
padding: 17px;
cursor: pointer;
// justify-content: center;
}

.top_image{
  width: 16.51px;
height: 16.54px;
}
.menu-list{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  justify-content: flex-start;
  padding: 10px;
  line-height: 19.21px;
  color: rgba(47, 48, 52, 1);
}
.mian_body{
  display: flex;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.mian_left{
  display: flex;
  width: 240px;
  height: 402px;
  justify-content: flex-start;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(248, 249, 254, 1);
}

.mian_right{
  display: flex;
  width: 734px;
  // height: 99vh;
  margin-left: 20px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(248, 249, 254, 1);
}



.help_text{
font-size: 16px;
color: rgba(0, 0, 0, 1);
margin-right: 30px;
margin-left: 5px;
}


.top_left{
    display: flex;
  align-items: center;
margin-left: 20px;
}

.top_right{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}
.gj—image{
  width: 514px;
height: 254px;
}
.gjj—image{
  width: 514px;
height: 213px;
}
.help_image{
  width: 95px;
height: 18px;
}
.el-aside{
  display: none !important;
}
.home {
  // width: 90vw;
  height: 100%;
  width: 100%;
  // height: 100%;
    background: rgba(0, 0, 1, 1);
  // border-radius: 15px;
   border-radius: 0px;
  position: absolute;
  left: 50%;
  top: 51%;
  transform: translate(-50%, -50%);
}

.agreement{
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(226, 214, 255, 0.47);
  text-align: left;
}
.topimage{
  width: 60px;
  height: 32px;
  margin-top:20px;
  margin-left: 20px;
  margin-right:24px
}
.top_text{
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 0px;
    margin-left: 10px;
    // line-height: 24px;
    color: rgba(56, 56, 56, 1);
    text-align: left;
    vertical-align: top;
}
.top{
  display:flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  justify-content: space-between;
  height: 65px;
  line-height: 74px;
  margin-top: -10px;
  background: rgba(255, 255, 255, 1);
}

.main{
    display: flex;
    flex-direction: row;
    align-content: center;
    text-align: center;
    background-image: url("@/assets/img/help_image.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 110%;
 }
 .bg{
    position: absolute;
    width: 268px;
    height: 308.67px;
    margin-top: -178px;
    margin-left: 112px;
 }
 .login_left{
    display: flex;
 }
 .login_right{
    display: flex;
    flex-direction: column;
    justify-content: center;
   //  margin-left: -40px;
   //  margin-top: -30px;
    align-content: center;
    text-align: center;
   //  width: 100vh;
 }
 .image{
    position: relative;  
    width: 276px;
    height: 58px;
    z-index: 9999;
 }
 .do_login{
    margin-top: 49px;
    width: 230px; 
    height: 51px;  
 }
 .incon_image{
   margin-right: 10px;
   width: 12px;
   height: 12px;
 }
.login_text{
    font-size: 48px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 57.6px;
    color: rgba(105, 41, 255, 1);
    text-align: left;
    vertical-align: top;
    margin-top: auto;
}

 .el-main {
    padding: 0px !important;
}




</style>