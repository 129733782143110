<template>
  <div id="app">
    <Home></Home>
  </div>
</template>

<script>
import Home from './view/home.vue'
//import Home from './view/Index.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style lang="scss">
@import url(./assets/font/iconfont.css);
.iconfont {
  font-family: "iconfont" !important;
  font-style: normal;
  font-size: 25px;
  vertical-align: middle;
  color: rgb(117,120,137);
  transition: .3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  padding: 0;
  margin: 0;
}

#app {
  width: 100vw;
  height: 100vh;
  // background-color: rgb(151,157,167);
}

.el-popper[x-placement^=top] .popper__arrow::after {
    bottom: -0.2px;
    margin-left: -6px;
    border-top-color: rgba(231, 221, 237, 1) !important;
  }


.el-popover.popoverStyle{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(33, 33, 33, 1);
  background: rgba(231, 221, 237, 1);
  margin-top: 16px !important;
  margin-bottom: 16px !important;

	}

  .el-popover.popoverStyleImage{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(0, 186, 173, 1);
  background: rgba(201, 255, 251, 1);
  margin-top: 16px !important;
  margin-bottom: 16px !important;

	}
  .el-popconfirm__action{
    margin-top: 10px !important;
  }
.el-popover{
  background: rgba(231, 221, 237, 1)!important;
}
.el-button--text{
  color: rgba(121, 72, 234, 0.8)!important;;
}

.el-popconfirm__action {
 
  // 交换 确定 和 取消 按钮的位置
  
  .el-button:nth-child(2) {
    
    // background: #ffffff;
    border-radius: 4px;
    background: rgba(121, 72, 234, 1);
    border-color: rgba(121, 72, 234, 1);
    
  }
}



</style>