<template>
  <div class="chat-window">
    <div class="top" style="border-radius: 12px;background: rgba(24, 23, 26, 1);border: 1.4px solid rgba(56, 56, 56, 1);">
       <div class="model_select">
         <img src="@/assets/img/model_select.png" style="width: 85px;height: 30px;">
       </div>
      <div style="display:flex;margin-top:5px;margin-left:20px">
          <div class="model" :class="[model_select==index?'model_active':'']" v-for="(item,index) in model_list" :key="index" @click="select_model(item,index)">
             <img src="@/assets/images/3443434343x.png" class="select-image"  v-if="model_select==index">
             <span class="model_title">{{item.name}}</span>
             <div class="model_main">
                <img :src="item.logo_url" style="width: 108px;height: 96px;">
                <span class="model_text">{{item.description}}</span>                
             </div>
             <el-select v-model="value" placeholder="请选择" v-if="index==1" @change="handleChange">
                  <el-option
                    v-for="(styles,index) in item.styles" 
                    :key="index"
                    :label="styles.display"
                    :value="styles.value">
                  </el-option>
                </el-select>
          </div>
          
          
          <!-- <div class="model model_active">
             <span class="model_title ">通用模型</span>
             <div class="model_main">
                <img src="@/assets/img/model_images.png" style="width: 108px;height: 96px;">
                <span class="model_text">适用于节日海报、电商、广告、公众号配图等多个日常场景（待确认)</span>
             </div>
          </div> -->
          <div class="model_submit" @click="submit_loading==false?sendText():''">
            <el-button type="primary"  :loading="submit_loading" style="width: 132px;height: 40px;background: radial-gradient(63.95% 86.21% at 86.04651162790698% 87.93103448275862%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(52.91% 177.59% at 26.16279069767442% 20.689655172413794%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 76.6%, rgba(255, 255, 255, 0) 100%), rgba(101, 67, 204, 1);border: none; border-radius: 20px;">立即生成</el-button>
             <!-- <img src="@/assets/img/model_submit.png" style="width: 132px;height: 40px;"> -->
          </div>
      </div>
    
      
    </div>
    
    <div class="botoom">
      <div class="chat-content" ref="chatContent">
        <div class="chat-wrapper" v-if="chatList.length>0" v-for="(item,index) in chatList" :key="index">          
          <div class="chat-friend">
            <div class="info-time">
              <span class="send_time">{{ item.ctime_display}}</span>
              <div class="send_image" >
                <img src="@/assets/img/download.png" style="width: 16px;;height: 16px;" @click="download(item)">
                <img src="@/assets/img/middle.png" style="width: 1px;height: 12px;">
                
                    <el-popconfirm
                      title="这条记录确定删除吗？"
                      class="del-button"
                      @confirm="delImage(item,index)"
                    >                   
                   <img src="@/assets/img/deal_msg.png" style="width: 16px;height: 16px;" slot="reference" >    
                
                  </el-popconfirm>
              </div>            
            </div>
            <div class="info-time">
              <span>{{ item.description }}</span>            
            </div>     
            <div class="tag_list">
              <el-tag type="info" class="tag_one" v-if="item.condition.model_style_display" > {{item.condition.model_style_display}}</el-tag>
              <el-tag type="info" class="tag_one">比例：{{item.condition.basic.scale}}</el-tag>
              <el-tag type="info" class="tag_one">种子数：{{item.condition.advanced.seed_num}}</el-tag>
              <el-tag type="info" class="tag_one">步数：{{item.condition.advanced.step_num}}</el-tag>
             </div>
                    
            <div class="chat-img">
              <div class="word-file" v-for="(images,index) in item.pics" :key="index">
                <el-image class="make—image"      :src="images.url"      :fit="fit" @click="handlePictureCardPreview(images.url)"></el-image>

                   <!-- <img :src="images.url" style="padding-right: 10px;" @click="handlePictureCardPreview(images.url)"> -->
              </div>
            </div>            
          </div>
        </div>
        
        <div class="chat-empty" v-if="chatList.length==0">
          <div class="empty_info" >
            <img src="@/assets/img/empty.png" alt=""  style="width: 120px;height: 120px;"/>
            <span class="empty_text">让无处不在的灵感随时落地吧～</span>
          </div>
        </div>
      </div>
      <!-- <div class="chatInputs">
        <div class="emoji boxinput" @click="clickEmoji">
          <img src="@/assets/img/emoji/smiling-face.png" alt="" />
        </div>
        <div class="emoji-content">
          <Emoji
            v-show="showEmoji"
            @sendEmoji="sendEmoji"
            @closeEmoji="clickEmoji"
          ></Emoji>
        </div>
        <input class="inputs" v-model="inputMsg" @keyup.enter="sendText" />
        <div class="send boxinput" @click="sendText">
          <img src="@/assets/img/emoji/rocket.png" alt="" />
        </div>
      </div> -->
    </div>
    <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog> 
  </div>
</template>

<script>
import { animation } from "@/util/util";
import { getTxtPicModel,getHistories,delHistories } from "@/api/getData";

import HeadPortrait from "@/components/HeadPortrait";
import Emoji from "@/components/Emoji";
import FileCard from "@/components/FileCard.vue";
export default {
  components: {
    HeadPortrait,
    Emoji,
    FileCard,
  },
  props: {
    frinedInfo: Object,
    default() {
      return {};
    },
  },
  watch: {
    frinedInfo() {
      this.submit_loading=false
      this.getFriendChatMsg();
    },
  },
  data() {
    return {
      chatList: [],
      inputMsg: "",
      showEmoji: false,
      submit_loading:false,
      friendInfo: {},
      images:false,
      model_list:[],
      model_select:0,
      dialogImageUrl:'',
      dialogVisible:false,
      fit:'cover',
      visible: false,
      value:'',
      modelId:1
    };
  },
  mounted() {
    this.getFriendChatMsg();
    this.txtPicModel()
  },
  methods: {
    handleChange(value) {
      this.model_select=1
      this.modelId=2
      // console.log('当前选中的值：', value);
      // 在这里处理选项变化的逻辑
    },
    select_model(item,index){
      this.modelId=item.id
      this.model_select=index
     
      // console.log('item',item,index)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file;
      this.dialogVisible = true;
    },

    //删除记录
    delImage(item,index){
      let job_id=item.job_id
      delHistories({job_id}).then(res=>{
        if(res.ok){
          this.chatList.splice(index,1)
          this.$message({
          message: '记录删除成功~',
          type: 'success'
        });
        }
      })
    },

    //下载图片
    download(item){
        for(let i=0;i<item.pics.length;i++){
           this.downloads(item.pics[i].url)
        }

    },

    downloads(Img) {
      var image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      var _this = this;
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = _this.projectName || "image"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = Img;
    },

    //获取聊天记录
    getFriendChatMsg() {
      getHistories({job_type:'txt2pic'}).then(res=>{
        if(res.ok){
          this.chatList =res.data
          // this.scrollBottom();
        }
      })
      // const chatHistory = localStorage.getItem('chatHistory');
      // if (chatHistory) {       
      //   this.chatList = JSON.parse(chatHistory);
      //   this.scrollBottom();
      // }     
    },
    //发送信息
    sendMsg(msgList) {
      this.chatList.push(msgList);
      this.scrollBottom();
    },
    //获取窗口高度并滚动至最底层
    scrollBottom() {
      this.$nextTick(() => {
        const scrollDom = this.$refs.chatContent;
        animation(scrollDom, scrollDom.scrollHeight - scrollDom.offsetHeight);
      });
    },
    //关闭标签框
    clickEmoji() {
      this.showEmoji = !this.showEmoji;
    },
    //发送文字信息
    sendText() {
        this.submit_loading=true
        // if(this.modelId==1){
        //    this.value=''
        // }
        this.$emit('personCardSort',{model_style:this.value,modelId:this.modelId})      
    },
    lgg(){
      this.submit_loading=false    
    },
    //获取模型列表
    txtPicModel(){
      getTxtPicModel().then(res=>{
        if(res.ok){

          this.model_list=res.data
          this.value=res.data[1].styles[0].value
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>

.tag_one{
margin-right: 5px;
border-radius: 4px;
background: rgba(37, 36, 41, 1);

border: 1px solid rgba(68, 67, 74, 1);
}
.tag_list{
  margin-top: 5px;
  margin-bottom: 5px;
  
}

.make—image{
width: 269px;height: 202px;margin-right: 10px;border-radius: 9px;
}

.model_active{
  border: 2px dashed rgb(125 109 175) !important;
}
.chat-window {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  position: relative;

  .top {
    margin-bottom: 15px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .head-pic {
      float: left;
    }
    .info-detail {
      float: left;
      margin: 5px 20px 0;
      .name {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
      }
      .detail {
        color: #9e9e9e;
        font-size: 12px;
        margin-top: 2px;
      }
    }
    .other-fun {
      float: right;
      margin-top: 20px;
      span {
        margin-left: 30px;
        cursor: pointer;
      }
      // .icon-tupian {

      // }
      input {
        display: none;
      }
    }
  }
  .botoom {
    width: 100%;
    height: 80vh;
    position: relative;
    border: 3px solid;
    // border-image: linear-gradient(135deg, rgba(68, 216, 255, 1), rgba(124, 68, 255, 1), rgba(68, 111, 255, 1)) 1 1;
    border-image: linear-gradient(90deg, rgb(113, 59, 240) 0%, rgb(116, 50, 232) 44.78%, rgb(118, 55, 246) 46.07%, rgb(83, 158, 244) 50%) 0.5 0.5;;
    clip-path: inset(1px round 5px);
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .chat-content {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 20px;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        width: 0; /* Safari,Chrome 隐藏滚动条 */
        height: 0; /* Safari,Chrome 隐藏滚动条 */
        display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
      }
      .chat-wrapper {
        position: relative;
        word-break: break-all;
        .chat-friend {
          width: 100%;
          float: left;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          .chat-text {
            max-width: 90%;
            padding: 20px;
            border-radius: 20px 20px 20px 5px;
            background-color: rgb(56, 60, 75);
            color: #fff;
            &:hover {
              background-color: rgb(39, 42, 55);
            }
          }
          .chat-img {
            img {
              width: 100px;
              height: 100px;
            }
          }
          .info-time {
            display: block;
            width: 100%;
            margin: 10px 0;
            color: #fff;
            font-size: 14px;
            img {
              width: 30px;
              height: 30px;
              // border-radius: 50%;
              vertical-align: middle;
              margin-right: 10px;
            }
            span:last-child {
              font-size: 16px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 21.95px;
              color: rgba(229, 229, 229, 1);
              text-align: left;
              vertical-align: top;
            }
          }
        }
        .chat-me {
          width: 100%;
          float: left;
          margin-bottom: 20px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          .chat-text {
            float: right;
            max-width: 90%;
            padding: 20px;
            border-radius: 20px 20px 5px 20px;
            background-color: rgb(29, 144, 245);
            color: #fff;
            &:hover {
              background-color: rgb(26, 129, 219);
            }
          }
          .chat-img {
            img {
              max-width: 300px;
              max-height: 200px;
              border-radius: 10px;
            }
          }
          .info-time {
            margin: 10px 0;
            color: #fff;
            font-size: 14px;
            display: flex;
            justify-content: flex-end;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              vertical-align: middle;
              margin-left: 10px;
            }
            span {
              line-height: 30px;
            }
            span:first-child {
              color: rgb(101, 104, 115);
              margin-right: 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .chatInputs {
      width: 90%;
      position: absolute;
      bottom: 0;
      margin: 3%;
      display: flex;
      .boxinput {
        width: 50px;
        height: 50px;
        background-color: rgb(66, 70, 86);
        border-radius: 15px;
        border: 1px solid rgb(80, 85, 103);
        position: relative;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .emoji {
        transition: 0.3s;
        &:hover {
          background-color: rgb(46, 49, 61);
          border: 1px solid rgb(71, 73, 82);
        }
      }

      .inputs {
        width: 90%;
        height: 50px;
        background-color: rgb(66, 70, 86);
        border-radius: 15px;
        border: 2px solid rgb(34, 135, 225);
        padding: 10px;
        box-sizing: border-box;
        transition: 0.2s;
        font-size: 20px;
        color: #fff;
        font-weight: 100;
        margin: 0 20px;
        &:focus {
          outline: none;
        }
      }
      .send {
        background-color: rgb(29, 144, 245);
        border: 0;
        transition: 0.3s;
        box-shadow: 0px 0px 5px 0px rgba(0, 136, 255);
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 136, 255);
        }
      }
    }
  }
}

.select-image{
  width: 75px;
    height: 75px;
    position: absolute;
    right: -5px;
    top: -5px;
}

.model_select{
  display: flex;
  margin-left: 15px;
  margin-top: 15px;
}
.model{
  display: flex;
  flex-direction: column;
  width: 287px;
  align-content: center;
  text-align: center;
  // height: 145px;
  opacity: 1;
  border-radius: 10px;
  border: 2px dashed rgba(42, 41, 46, 1);
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}
.model_title{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.95px;
  color: rgba(229, 229, 229, 1);
  text-align: left;
  width: 100%;
  margin-left: 10px;
  margin-top: 10px;
  padding-bottom: 6px;
  // border-bottom: 2px solid rgb(187, 184, 199);

}
.model_main{
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 2px solid rgba(42, 41, 46, 1);
}
.model_text{
  display: flex;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 25px;
  padding-left:13px;
  padding-right:13px;
  text-align: left;
  color: rgba(229, 229, 229, 0.5);
}
.model_submit{
  cursor: pointer;
  text-align: center;
  align-content: center;
  display: flex;
  position: absolute;
  right: 10px;
  margin-top:40px
}
.send_time{
  float: left;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 19.21px;
  color: rgba(229, 229, 229, 0.7);
  text-align: left;
  vertical-align: top;
}

.send_image{
  float: right;
}
.chat-img{
  display: flex;
  flex-direction: row;
}
.empty_text{
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 15px;
  line-height: 24.7px;
  // color: rgba(255, 255, 255, 1);
  // color: linear-gradient(to right, red, blue);
  text-align: left;
  vertical-align: top;
   /* 创建一个水平方向的颜色渐变 */
   background: linear-gradient(120deg, rgba(121, 107, 249, 1) 0%,rgba(40, 103, 248, 1) 49.57%, rgba(7, 213, 253, 1));
    /* 将文本透明度设置为0，以便背景渐变可见 */
    color: transparent;
    /* 使用背景渐变来填充文本背景 */
    -webkit-background-clip: text;
    background-clip: text;
}
.chat-empty{
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如果也需要水平居中的话 */
  height: 58vh; /* 父元素高度，根据实际情况设置 */
}
.empty_info{
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>