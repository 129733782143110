<template>
  <div class="paper-textarea-warpper">
    <textarea
      class="paper-textarea"
      :placeholder="placeholder"
      :rows="4"
      :value="textAreaValue"
      :maxlength="maxlength"
      @input="getTextAreaValue(this,msgtyp)"     
    />
    <div class="paper-length-maxlength">
      <span>{{ length }}</span> / <span>{{ maxlength }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '请输入提示词，描述你的创意。\n如"生成一副赵无极风格的抽象油画"（文字部分待确认)',
    },
    value: {
      type: [String, undefined],
      default: undefined,
    },
    msgtyp:{
      type:[String, Number,undefined]
    },
    IsReverse:{
      type:Boolean
    },
    maxlength: {
      type: [Number],
      default: 300,
    },
  },
  data() {
    return {
      textAreaValue: this.value,
      length: this.value?.length || 0,
    };
  },
  methods: {
    getTextAreaValue(e,msgtyp) {
      const event = e || window.event;
      const target = event.srcElement || event.taget;
      this.length = target.value.length;
      if (length <= this.maxlength) {
        this.textAreaValue = target.value;
      }
      this.$emit('change', {value:this.textAreaValue,msgtyp});
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  border: none;
  outline: none;
  resize: none;
}

.paper-textarea-warpper {
  border: none !important;
  position: relative;
  width: 375px;
  height: 125px;
  margin-left: 20px;
  margin-right: 20px;
  
    // margin: 0px 20px 15px 20px;
  background: rgba(42, 41, 46, 1);
  padding: 10px; /* 设置内边距为10px */
  border-radius: 4px;
  .paper-length-maxlength {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 13px;
    color: #bababa;
  }
}
.paper-textarea {
  border: none;
  width: 100%;
 background: rgba(42, 41, 46, 1);
  /** 禁止textarea拉伸 */
  resize: none;

  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.95px;
  // color: rgba(128, 128, 128, 1);
  color: #ffff;
  border-radius: 10px;
  padding-left:10px;
  /* WebKit, Blink, Edge */
  &::-webkit-input-placeholder {
    color: #bababa;
  }
  /* Mozilla Firefox 4 to 18 */
  &:-moz-placeholder {
    color: #bababa;
  }
  /* Mozilla Firefox 19+ */
  &::-moz-placeholder {
    color: #bababa;
  }
  /* Internet Explorer 10-11 */
  &:-ms-input-placeholder {
    color: #bababa;
  }
}

/* 修改placeholder的样式 */
.cpaper-textarea::-webkit-input-placeholder {

  white-space: pre-wrap;
  font-size: 14px;
  line-height: 1.8;
  color: #909090;
}
 
.paper-textareacustom-textarea::-moz-placeholder {
 
    white-space: pre-wrap;
 font-size: 14px;
  line-height: 1.8;
  color: #909090;
}
 
.paper-textarea:-ms-input-placeholder {
  
    white-space: pre-wrap;
 font-size: 14px;
  line-height: 1.8;
  color: #909090;
}
 
.paper-textarea::placeholder {

    white-space: pre-wrap;
  font-size: 14px;
  line-height: 1.8;
  color: #909090;
}

</style>
