<template>
    <div class="chatHome">  
      <div class="botoom">
        <div class="chat-content" ref="chatContent">           
          <div class="chat-wrapper">
            
            <div class="lora_main">
              <div class="lora_list" v-for="(item,index) in lora_list" :key="index">
                <div  class="tooltip" v-if="item.status =='finished'">
                  <el-tooltip placement="bottom">
                    <div slot="content" class="model_deal">    

                      <el-popconfirm
                      title="确定删除该模型吗？"
                      @confirm="delete_model(item,index)"
                    >    
                      <div slot="reference" class="delete_model">
                        <i class="el-icon-delete"></i>
                        <span style="margin-left: 10px;">删除模型</span>
                      </div> 
                    </el-popconfirm>      

                    </div>
                     <img src="@/assets/img/tooltip.png" class="del_image">     
                    </el-tooltip>          
                </div> 
                <div class="image_bg" >                  
                  <img :src="item.logo_url" class="image_bg" style="border-radius: 10px 10px 0px 0px;"> 
                  <div class="overlay" v-if="item.status =='training'">
                    <img src="@/assets/img/wait.png" class="mc_image">
                    <img src="@/assets/img/loading.png" class="mc_loading" @click="loading(item)">
                  </div>

                  <div class="overlay" v-if="item.status =='failed'">
                    <img src="@/assets/img/faile.png" style=" width: 132px;  height: 72px;margin-top: 120px;">   
                  
                    <el-popconfirm
                      title="确定删除该模型吗？"
                      @confirm="delete_model(item,index)"
                    >    

                      <div slot="reference" style="display: flex; position: absolute; padding-bottom: 12px; padding-top: 10px;  bottom: 0px; background: rgba(0, 0, 0, 0.72); width: 100%;cursor: pointer;">
                          <span style="color: rgba(255, 255, 255, 1);padding-left: 40%; ">删除</span>
                      </div>
                  </el-popconfirm> 

                  </div>


                </div>
               <div style="display:flex;    flex-direction: column;width:100%">
                  <span class="model_title">{{ item.trigger_word}}</span>
                  <div class="model_date">
                    <img src="@/assets/img/time.png" style="width: 13px;height: 13px;">
                    <span class="model_time">发布时间:{{ item.ctime_display}}</span>
                  </div>   
                </div>             
               

                <div style="display: flex; justify-content: center; width: 100%; cursor: pointer;"  v-if="item.status =='training'">
                  <img src="@/assets/img/model_wait.png" class="model_wait">  
                </div>
                <div style="display: flex; justify-content: center; width: 100%; cursor: pointer;" @click="useLoraing(item)" v-if="item.status =='finished'">
                  <img src="@/assets/img/model_star.png" class="model_wait">  
                </div>

              </div>                  
              <div class="lora_list_lora" @click="router_level" v-if="lora_list.length<2">
                <img src="@/assets/img/loar-xl.png" style="width: 117px;height: 202px;">                               
              </div>

            </div> 
          </div>    
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import PersonCard from "@/components/PersonCard.vue";
  import ChatWindow from "./chatwindow.vue";
  import PaperTextArea from '@/components/paper-textarea.vue';
  import { deleteModel,getModel,getModels} from "@/api/getData";
  import router from "@/router";
  export default {
    name: "App",
    components: {
      PersonCard,
      ChatWindow,
      'scf-input-textarea': PaperTextArea,
    },
    data() {
      return {
        lora_list:[]
      };
    },
    mounted() {
      this.LoraTrainlist();
    },
    methods: {
      loading(item){        
         getModels({id:item.id}).then(res=>{
        if(res.ok){
          if(res.data[0].status=='training'){
            this.$message({
              message: '模型训练中～',
              type: 'warning'
            });            
          } else if(res.data[0].status=='finished'){
            this.$message({
              message: '模型训练成功～',
              type: 'success'
            });
            this.LoraTrainlist(); 
          } else if(res.data[0].status=='failed'){
            this.$message({
              message: '模型训练失败～',
              type: 'error'
            });
            this.LoraTrainlist(); 
          }                  
        }
      })
       
      },
     //删除模型
     delete_model(item,index){     
      deleteModel({model_id:item.id}).then(res=>{         
         if(res.ok){
           this.lora_list.splice(index,1)
           localStorage.setItem('chatLoraHistory', JSON.stringify(this.lora_list));
           this.$message({
          message: '模型删除成功~',
          type: 'success'
        });
        this.LoraTrainlist();
         }else{
          this.$message.error(res.msg);
          this.LoraTrainlist();
         }
      })
    },

    //使用模型
    useLoraing(item){
        localStorage.setItem('lora_model', JSON.stringify(item))
        this.$router.push({name: 'UseLora',params: {model_id:item.id}});

    },
    //获取本地信息
     LoraTrainlist(){
       

      getModel().then(res=>{
        if(res.ok){
          if( res.data.length<=0){
            this.$router.push({
              name: "Lora",
            }, () => {});
          }else{
            this.lora_list=res.data
          }
         
        }
      })
      // const chatLoraHistory = localStorage.getItem('chatLoraHistory');
      // if (chatLoraHistory) {
      //   try {
      //     let list=JSON.parse(chatLoraHistory)
      //     let upate=false
      //     for(let i=0;i<list.length;i++){
      //       if(list[i].status=='training'){
      //         getModel({id:list[i].id}).then(res=>{
      //           if(res.ok){
      //             let model=res.data[0];
      //             if(model.status=='finished'){
      //               list.splice(i,1)
      //               list.push(model)
      //               upate=true
      //             }
      //           }                
      //         })
      //       }
      //     }
      //     if(upate){
      //       this.lora_list=list
      //       localStorage.setItem('chatLoraHistory', JSON.stringify(this.lora_list));
      //     }else {            
      //       this.lora_list = JSON.parse(chatLoraHistory);
      //     }         
      //   } catch (e) {
      //   }
      //}
    },
    router_level(){
        this.$router.push({
              name: "LoraApply",
            }, () => {});
        },      
    },
   
  };
  </script>
  <style lang="scss" scoped>
    .mc_loading{
      cursor: pointer;
      position:absolute;
      right: 5px;
      top: 5px;
      width: 34px;
height: 34px;
    }
.del_image{
  width: 30px;height: 30px;
}
.mc_image{
  width: 132px;
  height: 72px;
  margin-top: 120px;
}
.model_wait{
width: 123px ;height: 40px;margin-top: 30px;
}
 .image_bg{
  display:flex;width: 255px;height: 306px;
 }

    @media screen and (min-width: 768px){

    }
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
width: 255px;
height: 306px;

 
  
}




.tooltip{
  position: absolute;
  right: 20px; /* 靠右边对齐 */
  top: 20px; /* 也可以设置bottom: 0;使其在容器底部对齐 */
  cursor: pointer;
  
}
.model_deal{
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.95px;
  color: rgba(245, 105, 105, 1);
}

  :deep(.el-button){
    background: none;
    border: none;
  }
  
  .lora_main{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
   
    padding: 10px;
    border-radius: 12px;
  }
  .botoom {
    width: 100%;
    height: 68vh;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
  }
    
      .chat-wrapper {
        display: flex;
        flex-wrap: wrap;
      
        word-break: break-all;
        flex-direction: row;
      
     
  }
  .lora_list{
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    position: relative;
    // flex-direction: column;
    
    background: rgba(24, 23, 26, 1);
    border-radius: 12px;
    width: 255px;
    height: 447px;
  }

  .lora_list_lora{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(24, 23, 26, 1);
    border-radius: 12px;
    width: 255px;
    height: 447px;
  }
  .model_title{
    
    display: block;
    float: left;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 27.44px;
    color: rgba(255, 255, 255, 0.9);
    text-align: left;
   
    margin-top: 20px;
    margin-left: 15px;
  }
  .model_date{
    display: flex;
    margin-top: 50px;
    margin-left: 5px;
    // align-content: center;
    text-align: center;
    font-size: 14px;
    padding-bottom: 20px;
  }
  .model_time{
  display: block;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 16.46px;
  color: rgba(255, 255, 255, 0.46);
  text-align: left;
  margin-left: 5px;
  }
  </style>