<template>
  <div class="nav">
    <div class="nav-menu-wrapper">
      <ul class="menu-list">
        <li
          v-for="(item, index) in menuList"
          :key="index"
          class="nav_class"
          :class="{ activeNav: index == current }"
          @click="changeMenu(index)"
        >
          <div class="block"></div>
          <div class="nav_right">
            <img class="top_image" :src="item.image" style="">
            <span class="iconfont"  style="font-size:13px;">{{item.name}}</span>

          </div>
           
         
        </li>
       
      </ul>
      
       
     
    </div>
  <div class="back—lora" @click="golora" v-if="path== 'UseLora'">
    <div class="nav_right_last">
      <div><img class="back_image" src="@/assets/images/bank_lora.png" style=""></div>
      <span class="iconfont"  style="font-size:13px;color: rgba(255, 255, 255, 1);margin-top:-5px">返回</span>
    </div>
  </div>
</div>
</template>

<script>
import HeadPortrait from "./HeadPortrait.vue";

export default {
  components: {
    HeadPortrait,
  },
  data() {
    return {
      menuList: [
        {'name':"文生图",'image':require("@/assets/img/image-text-new.png")},
        
        {'name':"图生图",'image':require("@/assets/img/image-image-new.png")},
        {'name':"LoRa训练",'image':require("@/assets/img/image-lora-new.png")},
        // {'name':"返回",'image':require("@/assets/img/image-lora-new.png")},
      ],
      current: 0,
      imgUrl: require('@/assets/img/head_portrait.jpg'),
      path:''
    };
  },
   watch: {
// 监听路由对象中的'$route'属性
    '$route': {
      handler: function (to, from) {
         let path=this.$route.path.replace(/\//g, '')
        // console.log('ok--1',path);
        this.path=path
        // 你可以在这里根据路由变化执行相应的逻辑
      },
      // 如果需要深度监听路由对象中的属性，可以设置deep选项为true
      deep: true
    }
  },
  mounted() {   
    this.tabnav()
   

  },
  methods: {
    tabnav(){
      let path=this.$route.path.replace(/\//g, '')
        // console.log('ok--1',path);
        if(path=='ChatHome'){
            this.current=0
        } else if(path=='ChatImage'){
            this.current=1
        } else {
            this.current=2
        }
        this.path=path
    },
    changeMenu(index) {
      switch (index) {
        case 0:
          this.$router.push({
            name: "ChatHome",
          }, () => {});
          break;
        case 1:
          this.$router.push({
            name: "ChatImage",
          }, () => {});
          break;
        case 2:
          this.$router.push({
            name: "Lora",
          }, () => {});
          break;
        case 3:
          this.$message("该功能还没有开发哦，敬请期待一下吧~🥳");
          break;
        case 4:
          this.$message("该功能还没有开发哦，敬请期待一下吧~🥳");
          break;
        default:
          this.$router.push({
            name: "ChatHome",
          });
      }

      this.current = index;
      this.tabnav()
    },
    golora(){
      this.$router.push({
            name: "Lora",
          }, () => {});
    }
  },
};
</script>

<style lang="scss" scoped>
.back—lora{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.nav_class{
  display: flex;
  flex-direction: column;
  align-content: center;
  // text-align: center;
  justify-content: center;
}
.nav {
  width: 100%;
  height: 90vh;
  position: relative;
  border-radius: 20px 0 0 20px;
  .nav-menu-wrapper {
    position: relative;
    top: 210px;
    
    transform: translate(0, -50%);
    .menu-list {
      display: block;
      width: 63px;
      margin-left: 15px;
      

      li {
        margin: 40px 0 0 30px;
        font-size: 16px;
        list-style: none;
        cursor: pointer;
        position: relative;
        .block {
          background-color: rgba(255, 255, 255, 1);
          position: absolute;
          left: -17px;
          width: 6px;
          height: 25px;
          transition: 0.5s;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          opacity: 0;
        }
        &:hover {
          span {
            color: rgba(255, 255, 255, 1);
          }
          .block {
            opacity: 1;
          }
        }
      }
    }
  }
  .own-pic {
    position: absolute;
    bottom: 10%;
    margin-left: 25px;
  }
}
.activeNav {
  span {
    color: rgba(255, 255, 255, 1);
  }
  .block {
    opacity: 1 !important;
  }
}
.top_image{
  margin-top: 20px;
  width: 56px;
height: 56px;
margin-bottom: 8px;
}
.nav_right{
  width: 60px;
  display: block; 
   text-align: center;
}
.back_image{
  margin-top: 20px;
  width: 32px;
  height: 32px;
  margin-bottom: -5px;
}
.nav_right_last{
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 50px;
  width: 60px;
  display: block; 
  left: 45px;
  align-content: center;
  text-align: center;
}
</style>